<template>
  <div>
    <ConnectionIndicator />
    <transition name="fade" mode="out-in">
      <SignUp v-if="publicKey" key="sign_up" />
      <div class="min-vh-100 d-flex flex-column justify-content-between" v-else key="login">
        <div></div>
        <div class="container p-4">
          <Authenticate/>
        </div>
        <div class="dark-blur px-sm-4 py-2 mt-5">
          <div class="container d-flex flex-wrap align-items-center justify-content-between">
            <div v-if="partnerBranding.logo">
              <a :href="partnerBranding.link" target="_blank">
                <img :src="partnerBranding.logo" style="height: 50px"/>
              </a>
            </div>
            <a class="text-decoration-none text-white float-sm-right" href="https://www.assemblyvoting.com" target="_blank">
              <span class="small me-2">{{ $t("js.authenticate.technology_by") }}</span>
              <img  alt="Assembly Voting" src="@assets/images/av-logo-horizontal-white.png" style="line-height: 0; height: 25px"/>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { useSignUpSessionStore } from "@/entrypoints/stores/sign_up_session";
import { useFrontendThemeStore } from "@/entrypoints/stores/frontend_theme";
import ConnectionIndicator from "@/components/frontend/shared/ConnectionIndicator.vue";
import Authenticate from "./Authenticate.vue";
import SignUp from "./SignUp.vue";

export default {
  components: {
    ConnectionIndicator,
    Authenticate,
    SignUp
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    ...mapState(useFrontendThemeStore, ["partnerBranding"]),
    ...mapState(useSignUpSessionStore, ["publicKey"]),
  },
}
</script>
